<template>
  <div>
    <van-image
        fit="scale-down"
        :src="indexImgUrl"
    />
    <van-notice-bar
        wrapable
        :scrollable="false"
        left-icon="volume-o"
        :text="vertxNoticeText"
    />
    <van-row class="user-links">
      <van-col span="5">
        <van-icon name="pending-payment" @click="toAddOrder"/>
        记账
      </van-col>
      <van-col span="5">
        <van-icon name="records" @click="toOrderList"/>
        查看订单
      </van-col>
      <van-col span="5">
        <van-icon name="bill" @click="toDebtList" />
        查看账单
      </van-col>
      <van-col span="5">
        <van-icon name="tosend" @click="toUser"/>
        商户
      </van-col>
      <van-col span="4">
        <van-icon name="logistics" @click="toPrice"/>
        价格
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="5">
        <van-icon name="birthday-cake-o" @click="toStock"/>
        库存
      </van-col>
      <van-col span="5">
        <van-icon name="shopping-cart-o" @click="toUnsalableProduct"/>
        滞销商品
      </van-col>
      <van-col span="5">
        <van-icon name="shopping-cart-o" @click="toPurchase"/>
        进货
      </van-col>
      <van-col span="5">
        <van-icon name="photo-o" @click="toFactory"/>
        厂家
      </van-col>
      <van-col span="4">
        <van-icon name="comment-o" @click="toJPrice"/>
        进价
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="5">
        <van-icon name="revoke" @click="toReturn"/>
        退货
      </van-col>
      <van-col span="5">
        <van-icon name="comment-o" @click="toGreeting"/>
        客服
      </van-col>
      <van-col span="5">
        <van-icon name="photo-o" @click="toWT"/>
        无题
      </van-col>
      <van-col span="5">
        <van-icon name="photo-o" @click="toGreetings"/>
        无题
      </van-col>
    </van-row>

    <!--    <van-cell-group class="user-group">-->
    <!--      <van-cell icon="records" title="全部订单" is-link />-->
    <!--    </van-cell-group>-->

    <!--    <van-cell-group>-->
    <!--      <van-cell icon="points" title="我的积分" is-link />-->
    <!--      <van-cell icon="gold-coin-o" title="我的优惠券" is-link />-->
    <!--      <van-cell icon="gift-o" title="我收到的礼物" is-link />-->
    <!--    </van-cell-group>-->
        <van-cell-group class="user-group">
          <van-row class="user-links">
            <van-col span="24">
              <span class="my-van-submit-bar__price">¥<span class="my-van-submit-bar__price-integer">{{ debtAmount }}</span></span>
              总欠账
            </van-col>
          </van-row>
        </van-cell-group>
    <saleLine></saleLine>
    <creditColumn></creditColumn>
    <monthSaleAmountColumn></monthSaleAmountColumn>
    <saleLineMonth></saleLineMonth>
    <div style="margin-top: 150%;">
      <van-collapse v-model="activeNames">
        <van-collapse-item title="销售额" :is-link="link" name="1">
          <template #right-icon>
            <van-icon name="balance-o" color="#1989fa" size="25px" @click="getAnalysis"/>
          </template>
          <van-row class="user-links">
            <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfDay }}</span>
              本日
            </van-col>
            <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfWeek }}</span>
              本周
            </van-col>
            <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfMonth }}</span>
              本月
            </van-col>
            <van-col span="6">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfYear }}</span>
              今年
            </van-col>
          </van-row>
          <van-row class="user-links">
            <van-col span="24">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amount }}</span>
              总销量
            </van-col>
          </van-row>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-popup v-model="vertxUserNoticePopShow" style="width: 90%; max-height: 90%; background-color: #f000;">
      <van-image
          fit="fill"
          :src="greetPopImgUrl"
      />
    </van-popup>
  </div>
</template>

<script>
import {Row, Col, Icon, Cell, CellGroup, Collapse, CollapseItem, Toast, NoticeBar} from 'vant';
import * as orderAdminService from "@/api/admin/order";
import creditColumn from "@/view/chart/creditColumn";
import saleLine from "@/view/chart/saleLine";
import monthSaleAmountColumn from "@/view/chart/monthSaleAmountColumn.vue";
import saleLineMonth from "@/view/chart/saleLineMonth.vue";
import * as priceAdminService from "@/api/admin/price";
import {mapState} from "vuex";
import $store from "@/store";
import {curUserId} from "@/utils/admin/auth";
import greetPopImgUrl from "@/static/image/greetPop.jpg";
import indexImgUrl from "@/static/image/jizhangindex1.png"

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [NoticeBar.name]: NoticeBar,
    creditColumn,
    saleLine,
    monthSaleAmountColumn,
    saleLineMonth
  },
  data() {
    return {
      activeNames: [],
      analysis: {},
      link: false,
      debtAmount: null,
      greetPopImgUrl: greetPopImgUrl,
      indexImgUrl: indexImgUrl
    };
  },
  computed: {
    // 监听全局socket消息状态
    ...mapState({
      vertxNoticeText: state => state.im.vertxNoticeText,
    }),
    vertxUserNoticePopShow: {
      get() {
        return this.$store.state.im.vertxUserNoticePopShow;
      },
      set(v) {
        this.$store.state.im.vertxUserNoticePopShow = v
      }
    }
  },
  mounted() {
  },
  created() {
    priceAdminService.getCreditAmount().then(res => {
      if (res.status === 200) {
        this.debtAmount = res.data
      }
    })
  },
  methods: {
    getAnalysis() {
      orderAdminService.getAnalysis().then(res => {
        if (res.status === 200) {
          this.analysis = res.data
          if (this.analysis.amountOfDay === null) {
            this.analysis.amountOfDay = 0
          }
          if (this.analysis.amountOfWeek === null) {
            this.analysis.amountOfWeek = 0
          }
          if (this.analysis.amountOfMonth === null) {
            this.analysis.amountOfMonth = 0
          }
          if (this.analysis.amountOfYear === null) {
            this.analysis.amountOfYear = 0
          }
          if (this.analysis.amount === null) {
            this.analysis.amount = 0
          }
        }
      })
    },
    toAddOrder() {
      this.$router.push({path: '/order'})
    },
    toOrderList() {
      this.$router.push({path: '/order/search'})
    },
    toDebtList() {
      this.$router.push({path: '/order/debt/search'})
    },
    toUser() {
      this.$router.push({path: '/user/list'})
    },
    toPrice() {
      this.$router.push({path: '/price/search'})
    },
    toStock() {
      this.$router.push({path: '/product/stock'})
    },
    toUnsalableProduct() {
      this.$router.push({path: '/product/stock/zhixiao'})
    },
    toPurchase() {
      this.$router.push({path: '/purchase/list'})
    },
    toReturn() {
      this.$router.push({path: '/return/list'})
    },
    toFactory() {
      this.$router.push({path: '/factory/list'})
    },
    toJPrice() {
      this.$router.push({path: '/price/purchaseList'})
    },
    toGreeting() {
      this.$router.push({path: '/websocket/websocketGreetings/admin'})
    },
    toGreetings() {
      this.$router.push({path: '/selectOrder'})
    },
    toWT() {
      let userId = curUserId()
      this.$eventBus.send('vertx-socket-js-user-greet-pop-send',
          {},
          {
            userId: userId,
          },
          (err, reply) => {
            console.log('vertx vertx-socket-js-user-greet-pop reply', reply, err)
          }
      )
      this.$eventBus.registerHandler('vertx-socket-js-user-greet-pop-' + userId, function (error, reply) {
        console.log('vertx received a vertx-socket-js-user-greet-pop message: ', error, {reply});
        if (error !== null) {
          return
        }
        $store.commit('setVertxUserNoticePopShow', true);
      });
    }
  }
};

</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
.my-van-submit-bar__price {
  color: #ee0a24;
  font-weight: 500;
  font-size: 12px;
  display: block;
  //margin-left: var(--van-padding-base);
}
.my-van-submit-bar__price-integer {
  font-size: 20px;
  font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif;
}
</style>
